<template>
	<div class="home_box">
		<div class="block">
			<el-carousel trigger="click" height="150px">
				<el-carousel-item v-for="item in imgCarousel" :key="item.id">
					<img
						class="pointer"
						:src="item.adImageUrl"
						alt=""
						@click="toNav(item.link)"
					/>
				</el-carousel-item>
			</el-carousel>
		</div>
		<!-- 果尔佳服务 -->
		<div class="serviceGuoErJ">
			<div class="serviceMain">
				<p class="title">果尔佳<span>服务</span></p>
				<div class="detailService">
					<div
						class="detail construct pointer"
						@mouseenter="enter(1)"
						@mouseleave="leave"
						@click="toRoute(1)"
					>
						<span class="detailTitle" v-if="isShow == 1 ? false : true"
							>建筑服务</span
						>
						<div class="dialog" v-else>
							<span>建筑服务</span>
							<p>再好的装修经不住时光的侵蚀再大的墙面问题果尔佳建筑服务</p>
							<div class="plus" @click="toRoute(1)">了解更多</div>
						</div>
					</div>
					<div
						class="detail skills pointer"
						@mouseenter="enter(2)"
						@mouseleave="leave"
						@click="toRoute(2)"
					>
						<span class="detailTitle" v-if="isShow == 2 ? false : true"
							>技能培训</span
						>
						<div class="dialog" v-else>
							<span>技能培训</span>
							<p>再好的装修经不住时光的侵蚀再大的墙面问题果尔佳建筑服务</p>
							<div class="plus" @click="toRoute(2)">了解更多</div>
						</div>
					</div>
					<div
						class="detail league pointer"
						@mouseenter="enter(3)"
						@mouseleave="leave"
						@click="toRoute(3)"
					>
						<span class="detailTitle" v-if="isShow == 3 ? false : true"
							>加盟申请</span
						>
						<div class="dialog" v-else>
							<span>加盟申请</span>
							<p>再好的装修经不住时光的侵蚀再大的墙面问题果尔佳建筑服务</p>
							<div class="plus" @click="toRoute(3)">了解更多</div>
						</div>
					</div>
					<div
						class="detail afterSale pointer"
						@mouseenter="enter(4)"
						@mouseleave="leave"
						@click="toRoute(4)"
					>
						<span class="detailTitle" v-if="isShow == 4 ? false : true"
							>售后服务</span
						>
						<div class="dialog" v-else>
							<span>售后服务</span>
							<p>再好的装修经不住时光的侵蚀再大的墙面问题果尔佳建筑服务</p>
							<div class="plus" @click="toRoute(4)">了解更多</div>
						</div>
					</div>
					<div
						class="detail product pointer"
						@mouseenter="enter(5)"
						@mouseleave="leave"
						@click="toRoute(5)"
					>
						<span class="detailTitle" v-if="isShow == 5 ? false : true"
							>产品采购</span
						>
						<div class="dialog" v-else>
							<span>产品采购</span>
							<p>再好的装修经不住时光的侵蚀再大的墙面问题果尔佳建筑服务</p>
							<div class="plus" @click="toRoute(5)">了解更多</div>
						</div>
					</div>
					<div
						class="detail server pointer"
						@mouseenter="enter(6)"
						@mouseleave="leave"
						@click="toRoute(6)"
					>
						<span class="detailTitle" v-if="isShow == 6 ? false : true"
							>服务体系</span
						>
						<div class="dialog" v-else>
							<span>服务体系</span>
							<p>再好的装修经不住时光的侵蚀再大的墙面问题果尔佳建筑服务</p>
							<div class="plus" @click="toRoute(6)">了解更多</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 服务案例 -->
		<serviceCase></serviceCase>
		<!-- 推荐商品 -->
		<div class="shopping">
			<div class="swiper_box">
				<p class="swiper_title">推荐<span>商品</span></p>
				<swiper class="swiper" ref="swiper" :options="swiperOption">
					<swiper-slide class="slide" v-for="item in goodsList" :key="item.id">
						<div class="goodsBox pointer" @click="toGoodsDetails(item.id)">
							<div class="goodsImg">
								<img :src="item.coverUrl" alt="" />
							</div>
							<div class="goodsName">{{ item.productName }}</div>
							<div class="goodsPrice_box">
								<div class="price">￥{{ Number(item.price).toFixed(2) }}</div>
							</div>
						</div>
					</swiper-slide>
					<div class="swiper-button-prev" slot="button-prev">
						<img
							class="icon_left_right"
							src="../../assets/img/left.png"
							alt=""
						/>
					</div>
					<div class="swiper-button-next" slot="button-next">
						<img
							class="icon_left_right"
							src="../../assets/img/right.png"
							alt=""
						/>
					</div>
				</swiper>
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import serviceCase from './components/serviceCase.vue'
export default {
	name: 'home',
	components: {
		Swiper,
		SwiperSlide,
		serviceCase,
	},
	data() {
		return {
			// 轮播图
			imgCarousel: [],
			// 商品推荐
			goodsList: [],
			// 果尔佳服务-弹框
			isShow: '',
			// 是否自动轮播
			isAuto: false,
			// 推荐商品
			swiperOption: {
				slidesPerView: 4,
				spaceBetween: 20,
				direction: 'horizontal',
				observer: true,
				observeParents: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				on: {
					resize: () => {
						this.$refs.swiper.$swiper.changeDirection(
							window.innerWidth <= 1200 ? 'vertical' : 'horizontal'
						)
					},
				},
			},
		}
	},

	mounted() {
		this.init()
	},

	methods: {
		// 数据初始化
		init() {
			// 商品推荐
			this.api
				.findRecommendGoods({ token: this.$store.state.userData.token })
				.then((res) => {
					if (res.data) {
						const dataList = JSON.parse(JSON.stringify(res.data))
						this.goodsList = dataList.filter((item) => {
							return item != null
						})
					}
				})
			// 轮播图
			this.api.queryAdvertise({ type: 4 }).then((res) => {
				if (res.data) {
					let data = JSON.parse(JSON.stringify(res.data))
					this.imgCarousel = data.filter((item) => {
						if (item.state != 0) {
							return item
						}
					})
				}
			})
		},
		// 鼠标划入划出事件
		enter(num) {
			this.isShow = num
		},
		leave() {
			this.isShow = ''
		},
		// 跳转商品详情
		toGoodsDetails(id) {
			sessionStorage.removeItem('navItem')
			let routeData = this.$router.resolve({
				path: '/mall/goodsDetails',
				query: {
					id: id,
				},
			})
			window.open(routeData.href, '_blank')
		},
		// 轮播图跳转
		toNav(url) {
			window.open(url, '_blank')
		},
		// 果尔佳服务跳转
		toRoute(data) {
			switch (data) {
				case 1:
					let routeData = this.$router.resolve({
						path: '/serviceSystem/construct',
						query: {
							id: data,
						},
					})
					window.open(routeData.href, '_blank')
					break
				case 2:
					let routerData = this.$router.resolve({
						path: '/serviceSystem/skill',
						query: {
							id: data,
						},
					})
					window.open(routerData.href, '_blank')
					break
				case 3:
					let routeDatas = this.$router.resolve({
						path: '/serviceSystem/join',
						query: {
							id: data,
						},
					})
					window.open(routeDatas.href, '_blank')
					break
				case 4:
					let routerDatas = this.$router.resolve({
						path: '/serviceSystem/afterSales',
						query: {
							id: data,
						},
					})
					window.open(routerDatas.href, '_blank')
					break
				case 5:
					let routes = this.$router.resolve({ path: '/homes/index' })
					window.open(routes.href, '_blank')
					break
				case 6:
					let routeDat = this.$router.resolve({
						path: '/serviceSystem/service',
						query: {
							id: data,
						},
					})
					window.open(routeDat.href, '_blank')
					break
				default:
					break
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.home_box {
	.block {
		::v-deep.el-carousel {
			.el-carousel__container {
				height: 450px !important;
				.el-carousel__arrow:hover {
					background-color: #03825a !important;
				}
			}
			.el-carousel__indicators {
				.el-carousel__indicator {
					.el-carousel__button {
						width: 7px;
						height: 7px;
						border-radius: 50%;
						background-color: #03825a;
						opacity: 0.3;
					}
				}
				.is-active {
					.el-carousel__button {
						background-color: #03825a;
						opacity: 1;
					}
				}
			}
		}
	}
	.serviceGuoErJ {
		width: 100%;
		padding-bottom: 88px;
		background: white;
		.serviceMain {
			width: 1200px;
			margin: 0 auto;
			.title {
				text-align: center;
				font-size: 40px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				padding-top: 79px;
				padding-bottom: 39px;
			}
			.title > span {
				color: #03825a;
			}
			.detailService {
				width: 1200px;
				height: 602px;
				margin: 0 auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-content: space-between;
				.detail {
					width: 396px;
					height: 298px;
					display: flex;
					align-items: center;
					justify-content: center;

					position: relative;
					.detailTitle {
						display: inline-block;
						width: 168px;
						height: 78px;
						text-align: center;
						line-height: 78px;
						background: rgba(255, 255, 255, 0.7);

						font-size: 30px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #000000;
					}
					.dialog {
						span {
							font-size: 30px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #03825a;
							border-bottom: 2px solid #03825a;
							padding-bottom: 21px;
						}
						p {
							font-size: 16px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #000000;
							margin-top: 32px;
							margin-bottom: 27px;
							width: 272px;
							text-align: center;
							// 省略号
							overflow: hidden;
							text-overflow: ellipsis;
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
						}
						.plus {
							width: 120px;
							height: 38px;
							background: #03825a;
							text-align: center;
							line-height: 38px;
							cursor: pointer;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffffff;
						}
					}
				}
				.construct {
					background: url('../../assets/img/serviceSystem/construct.png');
				}
				.construct:hover {
					background-size: 398px 300px;
				}
				.skills {
					background: url('../../assets/img/serviceSystem/skills.png');
				}
				.skills:hover {
					background-size: 398px 300px;
				}
				.league {
					background: url('../../assets/img/serviceSystem/league.png');
				}
				.league:hover {
					background-size: 398px 300px;
				}
				.afterSale {
					background: url('../../assets/img/serviceSystem/afterSale.png');
				}
				.afterSale:hover {
					background-size: 398px 300px;
				}
				.product {
					background: url('../../assets/img/serviceSystem/product.png');
				}
				.product:hover {
					background-size: 398px 300px;
				}
				.server {
					background: url('../../assets/img/serviceSystem/server.png');
				}
				.server:hover {
					background-size: 398px 300px;
				}
				.dialog {
					display: flex;
					flex-wrap: wrap;
					align-content: center;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					background: rgba(255, 255, 255, 0.8);
					width: 396px;
					height: 298px;
					position: absolute;
				}
			}
		}
	}
	.case {
		width: 100%;
		height: 617px;
		background: #ededed;
		overflow: hidden;
		.block {
			width: 1200px;
			height: 378px;
			margin: 0 auto;
			::v-deep.el-carousel {
				.el-carousel__container {
					height: 378px !important;
					.el-carousel__arrow:hover {
						background-color: #03825a !important;
					}
					.el-carousel__item {
						.detailCase {
							display: flex;
							background-color: white;
							.caseImg {
								width: 678px;
								height: 378px;
							}
							.text {
								padding-left: 42px;
								padding-right: 102px;
								.textTitle {
									font-size: 24px;
									font-family: Microsoft YaHei;
									font-weight: bold;
									color: #000000;
									padding-top: 45px;
									padding-bottom: 26px;
								}
								.textDetail {
									font-size: 14px;
									font-family: Microsoft YaHei;
									font-weight: 400;
									color: #000000;
									.timeAddress {
										margin-right: 21px;
									}
								}
								.content {
									padding-top: 24px;
								}
								.detailContent {
									padding-top: 12px;
								}
								.wrap {
									overflow: hidden;
									text-overflow: ellipsis;
									display: -webkit-box;
									-webkit-line-clamp: 9;
									-webkit-box-orient: vertical;
								}
							}
						}
					}
				}
				.el-carousel__indicators {
					display: none;
				}
			}
		}
		.caseTitle {
			font-size: 40px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #333333;
			text-align: center;
			margin-top: 80px;
			margin-bottom: 39px;
			span {
				color: #03825a;
			}
		}
	}
	.shopping {
		background: white;
		.swiper_box {
			width: 1200px;
			padding-bottom: 88px;
			margin: 0 auto;
			.swiper_title {
				font-size: 40px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #333333;
				text-align: center;
				padding-top: 79px;
				padding-bottom: 45px;
				span {
					color: #03825a;
				}
			}
			.goodsBox:hover {
				.goodsName {
					color: #e1251b;
				}
			}
			.goodsBox {
				background: #ffffff;
				box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
				border-radius: 1px;
				height: 398px;
				.goodsImg {
					img {
						width: 250px;
						height: 250px;
						margin: 0 auto;
						padding-top: 20px;
					}
				}
				.goodsName {
					padding-right: 20px;
					margin-top: 16px;
					padding-left: 20px;
					height: 40px;

					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;

					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.goodsPrice_box {
					margin-top: 20px;
					.price {
						width: 119px;
						height: 26px;
						line-height: 26px;
						font-size: 16px;
						font-weight: bold;
						color: #e1251b;
						padding-left: 20px;
					}
				}
			}
			::v-deep.swiper-container {
				height: 403px;
				border-left: 1px solid rgb(0 0 0 / 5%);
				border-right: 1px solid rgb(0 0 0 / 5%);
				.swiper-wrapper {
					margin-top: 3px;
					height: 398px;
					.swiper-slide {
						height: 398px;
					}
					.slide {
						// margin-left: 1px!important;
						// margin-right: 1px!important;
					}
				}
			}
		}
	}
}
</style>
<style scoped>
.el-carousel__item:nth-child(2n) {
	background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
	background-color: #d3dce6;
}
.swiper-button-prev {
	left: 0px;
	width: 24px;
	height: 38px;
	background: rgba(3, 130, 90, 0.8);
	border-radius: 0px 4px 4px 0px;
}
.swiper-button-next {
	right: 0px;
	width: 24px;
	height: 38px;
	background: rgba(3, 130, 90, 0.8);
	border-radius: 4px 0px 0px 4px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
	content: none;
}
.icon_left_right {
	width: 11px;
	height: 21px;
}
</style>