<template>
	<div class="case">
		<p class="caseTitle">服务<span>案例</span></p>
		<div class="block">
			<el-carousel trigger="click" height="150px">
				<el-carousel-item v-for="(item, index) in caseList" :key="index">
					<div class="detailCase">
						<img class="caseImg" :src="item.afterRepairUrl" alt="" />
						<div class="text">
							<p class="textTitle">{{ item.title }}</p>
							<div class="textDetail content" v-html="item.remark"></div>
						</div>
					</div>
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GejMallPcServicecase',

	data() {
		return {
			caseList: [],
		}
	},

	mounted() {
		this.init()
	},

	methods: {
		// 初始化数据
		init() {
			this.api.queryLists().then((res) => {
				if (res.data) {
					let data = JSON.parse(JSON.stringify(res.data))
					this.caseList = data.filter((item) => {
						if (item.state != 0) {
							return item
						}
					})
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.case {
	width: 100%;
	height: 617px;
	background: #ededed;
	overflow: hidden;
	.block {
		width: 1200px;
		height: 378px;
		margin: 0 auto;
		::v-deep.el-carousel {
			.el-carousel__container {
				height: 378px !important;
				.el-carousel__arrow:hover {
					background-color: #03825a !important;
				}
				.el-carousel__item {
					.detailCase {
						display: flex;
						background-color: white;
						.caseImg {
							width: 678px;
							height: 378px;
						}
						.text {
							padding-left: 42px;
							padding-right: 102px;
							.textTitle {
								font-size: 24px;
								font-family: Microsoft YaHei;
								font-weight: bold;
								color: #000000;
								padding-top: 45px;
								padding-bottom: 26px;
							}
							.textDetail {
								font-size: 14px;
								font-family: Microsoft YaHei;
								font-weight: 400;
								color: #000000;
								.timeAddress {
									margin-right: 21px;
								}
								img {
									width: 300px;
									height: 200px;
								}
							}
							.content {
								// padding-top: 24px;
								height: 230px;
								overflow: hidden;
								overflow-y: scroll;
							}
							.content::-webkit-scrollbar {
								display: none;
							}
							.detailContent {
								padding-top: 12px;
							}
							.wrap {
								overflow: hidden;
								text-overflow: ellipsis;
								display: -webkit-box;
								-webkit-line-clamp: 9;
								-webkit-box-orient: vertical;
							}
						}
					}
				}
			}
			.el-carousel__indicators {
				display: none;
			}
		}
	}
	.caseTitle {
		font-size: 40px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		text-align: center;
		margin-top: 80px;
		margin-bottom: 39px;
		span {
			color: #03825a;
		}
	}
}
</style>